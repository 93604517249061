<template>
<v-dialog v-model="dialog" width="500" scrollable>
    <v-card>
        <v-card-title>Informationen</v-card-title>
        <v-card-text>
            <b>Nach dem erfolgreichen Ablauf im letzten Jahr, wird die NEW-Wunschbaumaktion wieder in digitaler Form stattfinden. Wir bedanken uns bei allen Mitarbeiter:innen für die zahlreiche Beteiligung an der Aktion!</b>
            <v-divider class="my-2"></v-divider>
            <b>Wie könnt Ihr an der NEW-Wunschbaumaktion teilnehmen?</b><br>
            Auf der digitalen Wunschbaum-Seite kannst du einen Weihnachtswunsch deiner Wahl auswählen. Diesen findest du dann in deinem persönlichen Bereich unter "Meine Erfüllungen".
            Bitte beschafft und verpackt das Geschenk und gebt dieses an der Abgabestation auf der OKS ab oder versendet es mit dem Vermerk "Wunschbaumaktion" per Hauspost an die Poststelle an der OKS.
            <v-divider class="my-2"></v-divider>
            <b>Kann eingesehen werden, wer wie viele Wünsche erfüllt?</b><br>
            Nein, die NEW-Wunschbaumaktion ist, wie die letzten Jahre, freiwillig und wird nicht ausgewertet. Es kann nicht eingesehen werden, welche und wie viele Wünsche du erfüllst.
            <v-divider class="my-2"></v-divider>
            <b>Kann ich einsehen, welche Wünsche ich genommen habe?</b><br>
            Ja, du kannst jederzeit einsehen, welche Wünsche du ausgewählt hast. Im Bereich "Meine Erfüllungen" findest du eine entsprechende Übersicht. Hier kannst du die Wünsche außerdem abhaken, wenn du diese erfüllt hast.
            <v-divider class="my-2"></v-divider>
            <b>Ich habe einen Wunsch genommen, den ich nicht erfüllen kann</b><br>
            Wünsche, die du ausgewählt hast, kannst du jederzeit rückgängig machen. Dieser Wunsch wird dann wieder für alle Mitarbeiter:innen sichtbar.<br>
            Bitte beachte jedoch, dass andere Mitarbeiter:innen zum Erfüllen des Wunsches ebenfalls Zeit brauchen. Überlege dir deshalb vorher, ob du den Wunsch erfüllen kannst und gib den Wunsch - falls nötig - rechtzeitig zurück.
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false">OK</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
export default {
    data() {
        return {
            dialog: false,
        }
    },
    watch: {
        active() {
            this.dialog = true;
        }
    },
    props: {
        active: Number
    }
}
</script>