<template>
	<DenseContainer>
        <ShareDialog :url="dialogs.shareURL" :shareID="dialogs.shareID" :active="dialogs.share"></ShareDialog>
        <AddWish :active="dialogs.addWish" v-if="$store.state.auth.userClaims.wishes"></AddWish>
        <Permissions :active="dialogs.permissions" v-if="$store.state.auth.userClaims.users"></Permissions>
        <CampaignSettings :active="dialogs.campaign" v-if="$store.state.auth.userClaims.campaign"></CampaignSettings>
        <StatDialog :active="dialogs.statDialog" v-if="$store.state.auth.userClaims.campaign"></StatDialog>
        <InfoDialog :active="dialogs.info"></InfoDialog>
        <v-row v-if="$store.state.auth.userClaims.wishes || $store.state.auth.userClaims.campaign || $store.state.auth.userClaims.users">
            <v-col cols="12">
                <v-switch v-model="$store.state.adminMode" label="Bearbeitungsmodus" inset></v-switch>
            </v-col>
        </v-row>
        <v-row v-if="!$store.state.config.campaign.started">
            <v-col cols="12">
                <v-alert elevation="2" type="info" color="blue darken-2">Die NEW Wunschbaumaktion ist aktuell nicht gestartet. Nur Mitarbeiter, die in der Rechteverwaltung eingetragen sind, haben Zugriff auf die Inhalte dieser Webseite. Unter den Einstellungen kann die Aktion gestartet werden.</v-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card color="green darken-4" dark>
                    <v-img class="white--text align-center" height="auto" :src="require('@/assets/Images/img_0.jpg')">
                        <v-card-title>
                            <v-icon large left>mdi-pine-tree</v-icon>
                            <span>Wir wünschen euch allen eine schöne Weihnachtszeit!</span>
                        </v-card-title>
                        <v-card-text class="headline font-weight-bold">
                            Willkommen zur diesjährigen digitalen NEW-Wunschbaumaktion.<br>
                            Wähle gerne den Wunsch aus, den du erfüllen möchtest. <br>
                            Vielen Dank!
                        </v-card-text>
                        <v-card-actions>
                            <v-btn text @click="dialogs.info++">Weitere informationen</v-btn>
                        </v-card-actions>
                    </v-img>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="$store.state.adminMode">
            <v-col>
                <v-btn v-if="$store.state.auth.userClaims.wishes" color="primary" class="mr-2 my-1" @click="dialogs.addWish++"><v-icon left>mdi-plus</v-icon>Neuer Wunsch</v-btn>
                <v-btn v-if="$store.state.auth.userClaims.users" color="primary" class="mr-2 my-1" @click="dialogs.permissions++"><v-icon left>mdi-account-group</v-icon>Rechteverwaltung</v-btn>
                <v-btn v-if="$store.state.auth.userClaims.campaign" color="primary" class="mr-2 my-1" @click="dialogs.campaign++"><v-icon left>mdi-cog</v-icon>Einstellungen</v-btn>
                <v-btn v-if="$store.state.auth.userClaims.campaign" color="primary" class="my-1" @click="dialogs.statDialog++"><v-icon left>mdi-chart-bar</v-icon>Statistik</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="$store.state.isLoading">
            <v-col cols="12" class="text-center py-4">
                <v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
            </v-col>
        </v-row>
        <v-row v-show="!$store.state.isLoading" v-if="Object.keys($store.state.wishes).length == 0">
            <v-col cols="12">
                <v-alert elevation="2" dense type="info" color="primary">
                    Alle Wünsche wurden bereits vergeben!
                    Schauen Sie dennoch hin und wieder vorbei, falls ein Wunsch zurück gestellt wird.
                </v-alert>
            </v-col>
        </v-row>
		<v-row v-show="!$store.state.isLoading" v-else>
			<v-col cols="12" sm="6" md="4" v-for="(wish, key) in $store.state.wishes" :key="key">
				<WishItem :wish="wish" shareable @share="share"></WishItem>
			</v-col>
		</v-row>
        <v-row v-show="!$store.state.isLoading" v-if="lastPageSize >= pageSize">
            <v-col cols="12" sm="12">
                <v-btn @click="loadMore" color="primary" block :loading="isLoadingMore">Weitere Wünsche laden</v-btn>
            </v-col>
        </v-row>
	</DenseContainer>
</template>

<script>
import WishItem from '@/components/Items/WishItem';
import InfoDialog from '@/components/Dialogs/InfoDialog';
import AddWish from '@/components/Dialogs/AddWish';
import Permissions from '@/components/Dialogs/Permissions';
import ShareDialog from '@/components/Dialogs/ShareDialog';
import CampaignSettings from '@/components/Dialogs/CampaignSettings';
import StatDialog from '@/components/Dialogs/StatDialog';
import { collection, limit, onSnapshot, query, startAfter, where, orderBy } from '@firebase/firestore';
import { firestore } from '../firebase';

export default {
	data() {
		return {
            page: 1,
            cards: {},
            dialogs: {
                info: 0,
                addWish: 0,
                permissions: 0,
                campaign: 0,
                statDialog: 0,
                share: 0,
                shareURL: "",
                shareID: ""
            },
            isLoadingMore: false,
            scrolledToBottom: false,
            pageSize: 50,
            lastPageSize: 0,
            firebase: {
                ref: null,
                query: null,
                listener: [],
                wishesLive: {},
                lastDocument: null
            }
		}
    },
    methods: {
        share(shareURL) {
            this.dialogs.shareID = "";
            this.dialogs.shareURL = shareURL;
            this.dialogs.share++;
        },
        loadMore() {
            if(this.lastPageSize < this.pageSize) {
                return;
            }
            this.isLoadingMore = true;
            
            this.firebase.query = query(this.firebase.ref, startAfter(this.firebase.lastDocument), limit(this.pageSize))
            this.attachListener();
        },
        loadShare(id) {
            this.dialogs.shareID = id;
            this.dialogs.shareURL = "";
            this.dialogs.share++;
        },
        attachListener() {
            var id = this.firebase.listener.length;
            
            this.firebase.listener[id] = onSnapshot(this.firebase.query, (docs => {
                this.$set(this.firebase.wishesLive, id, {});
                this.lastPageSize = 0;
                docs.forEach(doc => {
                    var newDoc = doc.data();
                    newDoc._id = doc.id;
                    this.$set(this.$store.state.wishes, doc.id, newDoc);
                    this.$set(this.firebase.wishesLive[id], doc.id, true);
                    this.firebase.lastDocument = doc;
                    this.lastPageSize++;
                });
                this.$store.state.isLoading = false;
                this.isLoadingMore = false;
                this.$store.state.wishesLive = {};
                for(var index in this.firebase.wishesLive) {
                    for(var key in this.firebase.wishesLive[index]) {
                        this.$set(this.$store.state.wishesLive, key, true);
                    }
                }
            }));
        },
        detatchListener() {
            this.firebase.listener.forEach(listener => {
                listener();
            });
        },
        scollListener() {
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 10) {
                if(!this.scrolledToBottom) {
                    this.loadMore();
                }
                this.scrolledToBottom = true;
            }else{
                this.scrolledToBottom = false;
            }
        }
    },
    mounted() {
        if(this.$store.state.config.campaign.started !== true && !this.$store.state.auth.userClaims.access) {
            this.$router.push("/campaign");
            return;
        }
        if(this.$route.query.hasOwnProperty("share")) {
            this.loadShare(this.$route.query.share);
        }else if(sessionStorage.getItem("share") != null) {
            this.loadShare(sessionStorage.getItem("share") );
            sessionStorage.removeItem("share");
        }
        this.$store.state.isLoading = true;
        this.$store.state.wishes = {};
        this.firebase.ref = query(collection(firestore, "wishes"), where("visible", "==", true), where("uid", "==", null), orderBy("timestamp"))
        this.firebase.query = query(this.firebase.ref, limit(this.pageSize))
        this.attachListener();
        addEventListener("scroll", this.scollListener);
        this.$store.dispatch("attachTakenListener");
    },
    destroyed() {
        this.detatchListener();
        removeEventListener("scroll", this.scollListener);
    },
	components: {
        WishItem,
        InfoDialog,
        AddWish,
        Permissions,
        ShareDialog,
        CampaignSettings,
        StatDialog
	},
};
</script>